import { ResponsiveImageConfig } from "~/components/Image";
import { AmplienceDynamicImageConfigKeys } from "~/components/Image/Image.types";

import type { Dimensions } from "./Logo.types";

export const RESPONSIVE_IMAGE_CONFIG: ResponsiveImageConfig = {
  wideDesktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 300,
  },
  desktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 300,
  },
  tablet: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 200,
  },
  mobile: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 200,
  },
};

export const DIMENSIONS: Dimensions = {
  height: {
    small: 13,
    medium: 18,
    large: 28,
  },
  width: {
    small: 97.9,
    medium: 135.5,
    large: 213.5,
  },
};
