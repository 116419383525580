import type { Image } from "~/bff/types/Image";

import { DIMENSIONS } from "./Logo.constants";
export interface ImageSizes {
  small: number;
  medium: number;
  large: number;
}

export interface Dimensions {
  height: ImageSizes;
  width: ImageSizes;
}

export type LogoSizeKey = "small" | "medium" | "large";

export interface LogoProps {
  className?: string;
  size?: LogoSizeKey;
  image?: Image;
}

export interface UseStylesProps {
  size?: LogoSizeKey;
}

export enum LogoSize {
  SMALL = DIMENSIONS.height.small,
  MEDIUM = DIMENSIONS.height.medium,
  LARGE = DIMENSIONS.height.large,
}
