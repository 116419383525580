"use client";
import React from "react";

import { Container, styled } from "@mui/material";

import { LinkedLogo } from "~/components/Logo/components/LinkedLogo";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { ServiceErrorInfo } from "./components/ServiceErrorInfo";

const PREFIX = "StyledServiceErrorPage";

const classes = {
  logo: `${PREFIX}-logo`,
};

const StyledServiceErrorPage = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`& .${classes.logo}`]: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(4),
      height: 16,
      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(7),
        height: 28,
      },
    },
  };
});

export const ServiceErrorPage = () => {
  return (
    <StyledServiceErrorPage {...getTestAutomationProps("page-500")}>
      <LinkedLogo className={classes.logo} size="large" href="/" />
      <ServiceErrorInfo />
    </StyledServiceErrorPage>
  );
};
