"use client";
import React from "react";

import { Box, styled } from "@mui/material";

import { Image } from "~/bff/types/Image";
import { CombinedLink } from "~/components/CombinedLink";
import { Logo, LogoSize, LogoSizeKey } from "~/components/Logo";
import { COLOR_FOCUSED } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledLinkedLogo";

const classes = {
  logoLink: `${PREFIX}-logoLink`,
};

const StyledLinkedLogo = styled(Box)(() => ({
  [`& .${classes.logoLink}`]: {
    display: "flex",
    maxHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: LogoSize.LARGE,

    "&.Mui-focusVisible, &:focus-visible": {
      outlineWidth: "2px",
      outlineOffset: "4px",
      outlineStyle: "solid",
      outlineColor: COLOR_FOCUSED,
    },
  },
}));

export interface LinkedLogoProps {
  className?: string;
  image?: Image;
  size?: LogoSizeKey;
  href: string;
  testAutomationId?: string;
  onClick?: () => void;
}

export const LinkedLogo = ({
  className,
  image,
  size,
  href = "/",
  onClick,
  testAutomationId = "linked-logo",
}: LinkedLogoProps) => {
  return (
    <StyledLinkedLogo
      className={className}
      {...getTestAutomationProps(testAutomationId)}
    >
      <CombinedLink
        key={image?.source?.id}
        href={href}
        underline="none"
        onClick={onClick}
        className={classes.logoLink}
      >
        <Logo image={image} size={size} />
      </CombinedLink>
    </StyledLinkedLogo>
  );
};
