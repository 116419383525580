"use client";
import React from "react";

import { Grid, styled, Typography } from "@mui/material";
import { useParams } from "next/navigation";

import { getLanguageCodeByLocale } from "~/constants/i18n";
import { ERROR_PAGE } from "~/constants/translations";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getPageLocale } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

export interface ServiceErrorInfoProps {
  className?: string;
}

const PREFIX = "StyledGrid";
const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
};

const StyledGrid = styled(Grid)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      maxWidth: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },
    [`& .${classes.title}`]: {
      ...theme.typography.h4,
      marginBottom: theme.spacing(2),
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.h3,
        textAlign: "center",
        marginBottom: theme.spacing(3),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        ...theme.typography.h2,
        textAlign: "center",
        marginBottom: theme.spacing(4),
      },
    },
    [`& .${classes.text}`]: {
      marginBottom: theme.spacing(4),
      ...theme.typography.body1,
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(5),
        ...theme.typography.h6,
        textAlign: "center",
      },
    },
  };
});

export const ServiceErrorInfo = ({ className }: ServiceErrorInfoProps) => {
  const { locale } = useParams();
  const pageLocale = getPageLocale(locale as string);
  const language = getLanguageCodeByLocale(pageLocale);
  const texts = ERROR_PAGE[pageLocale] ?? ERROR_PAGE[language] ?? ERROR_PAGE.default;

  return (
    <StyledGrid item xs={12} sm={8} className={classes.root}>
      <Typography className={classes.title} {...getTestAutomationProps("title")}>
        {texts.title}
      </Typography>
      <Typography className={classes.text} {...getTestAutomationProps("text")}>
        {texts.description}
      </Typography>
    </StyledGrid>
  );
};
