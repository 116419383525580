"use client";
import React from "react";

import { cx } from "@emotion/css";
import { styled } from "@mui/material";
import { useParams } from "next/navigation";

import { Image } from "~/components/Image";
import { getLogoPathByLocale } from "~/constants/i18n";
import { getImageSrc } from "~/utils/image";
import { getPageLocale } from "~/utils/locales";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { DIMENSIONS, RESPONSIVE_IMAGE_CONFIG } from "./Logo.constants";
import type { LogoProps } from "./Logo.types";

const PREFIX = "StyledImage";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledImage = styled(Image)(() => ({
  [`&.${classes.root}`]: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export const Logo = (props: LogoProps) => {
  const { className, image, size = "large" } = props;

  const { locale } = useParams();
  const pageLocale = getPageLocale(locale as string);
  const logoPath = getLogoPathByLocale(pageLocale);

  return (
    <StyledImage
      className={cx(classes.root, className)}
      responsiveImageConfig={image?.source ? RESPONSIVE_IMAGE_CONFIG : undefined}
      alt={prepareTextAttribute(image?.alt) ?? "Logo"}
      title={prepareTextAttribute(image?.title)}
      width={DIMENSIONS.width[size]}
      height={DIMENSIONS.height[size]}
      src={image?.source ? getImageSrc(image.source) : logoPath}
      {...getTestAutomationProps("logo")}
    />
  );
};
