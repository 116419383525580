"use client";

import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { ServiceErrorPage } from "~/components/ServiceErrorPage";
import { THEME } from "~/theme";

export default function Error() {
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <ServiceErrorPage />
    </ThemeProvider>
  );
}
