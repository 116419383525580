import React, { useEffect, useMemo, useState, MouseEvent, useCallback } from "react";

import { Link as MuiLink, LinkProps } from "@mui/material";
import NextLink from "next/link";
import { useParams } from "next/navigation";

import { useSaveScrollPosition } from "~/hooks/useSaveScrollPosition";
import { isMailToHref } from "~/utils/isMailToHref";
import { getLinkUrl, getPageLocale } from "~/utils/locales";
import { prepareLinkProps } from "~/utils/prepareLinkProps";
import { getTestAutomationProps } from "~/utils/testUtils";

interface ExtendedLinkProps extends LinkProps {
  locale?: string;
  ariaLabel?: string;
}

export const CombinedLink = React.forwardRef<HTMLAnchorElement, ExtendedLinkProps>(
  ({ children, ...props }, ref) => {
    const { locale: routerLocale } = useParams();
    const saveScrollPos = useSaveScrollPosition();
    const { href, className, locale, onClick, ariaLabel } = props;

    const linkLocale = useMemo(
      () => getPageLocale(routerLocale as string).toLowerCase(),
      [routerLocale]
    );

    const [linkChildren, setLinkChildren] = useState<React.ReactNode>(null);
    const [linkProps, setLinkProps] = useState<LinkProps>(props);

    // The solution is to solve hydration issue,
    // since prepareLinkProps function uses window object.
    // TODO: TECH-DEBT: Do research of next/dynamic.
    useEffect(() => {
      const preparedPropsAndChildren = prepareLinkProps(props, children);
      setLinkChildren(preparedPropsAndChildren?.children);
      setLinkProps(preparedPropsAndChildren?.props);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClick = useCallback(
      (e: MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
          onClick(e);
        }

        if (linkProps?.target !== "_blank") {
          saveScrollPos();
        }
      },
      [onClick, linkProps?.target, saveScrollPos]
    );

    const link = (
      <MuiLink
        ref={ref}
        className={className}
        {...getTestAutomationProps("link")}
        {...linkProps}
        onClick={handleOnClick}
        aria-label={ariaLabel}
      >
        {linkChildren ?? children}
      </MuiLink>
    );

    return href ? (
      <NextLink
        prefetch={false}
        href={isMailToHref(href) ? href : getLinkUrl(locale ?? linkLocale, href)}
        passHref
        locale={false}
        legacyBehavior
      >
        {link}
      </NextLink>
    ) : (
      link
    );
  }
);

CombinedLink.displayName = "CombinedLink";
