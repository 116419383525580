import { useCallback } from "react";

import { usePathname } from "next/navigation";

import { useSessionStorage } from "~/hooks/useSessionStorage";

import { ScrollPosData } from "./useSaveScrollPosition.types";

export const KEY_PREFIX = "SCROLL_ANCHOR";

type UseSaveScrollPositionReturnType = () => void;

export const useSaveScrollPosition = (): UseSaveScrollPositionReturnType => {
  const { setItem } = useSessionStorage();
  const pathname = usePathname();
  const fullAnchorKey = [KEY_PREFIX, pathname].join(":");

  const saveScrollPos = useCallback(() => {
    const scrollPos: ScrollPosData = {
      x: window.scrollX,
      y: window.scrollY,
    };

    setItem(fullAnchorKey, JSON.stringify(scrollPos));
  }, [fullAnchorKey, setItem]);

  return saveScrollPos;
};
