import { ReactNode } from "react";

import { LinkProps } from "@mui/material";

import { INTERNAL_LINK } from "~/constants/internalHosts";

interface LinkPropsReturn {
  props: LinkProps;
  children: ReactNode;
}

export const prepareNofollow = (children: ReactNode): [string, ReactNode] => {
  if (typeof children === "string" && children.includes(" [nofollow]")) {
    children = children.replace(" [nofollow]", "");
    return ["nofollow", children];
  } else if (
    Array.isArray(children) &&
    children.every(
      (children) => typeof children === "string" && children.includes(" [nofollow]")
    )
  ) {
    children = children.map((children) => children.replace(" [nofollow]", ""));
    return ["nofollow", children];
  }
  return ["", children];
};

export const prepareLinkProps = (
  props: LinkProps,
  children: ReactNode
): LinkPropsReturn => {
  const { target, href } = props;
  const preparedProps = { ...props };
  const [rel, preparedChildren] = prepareNofollow(children);
  const preparedLink = { props: preparedProps, children: preparedChildren };
  preparedLink.props.rel = rel;
  // children = preparedChildren;

  if (target) {
    return preparedLink;
  }

  try {
    const { hostname } = new URL(href ?? "");

    if (INTERNAL_LINK.includes(hostname)) {
      return preparedLink;
    }
    if (!IS_SERVER && hostname === window?.location?.hostname) {
      return preparedLink;
    }
    preparedLink.props.target = "_blank";
    return preparedLink;
  } catch (err) {
    return preparedLink;
  }
};
