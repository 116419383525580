interface ErrorPageTranslation {
  [key: string]: { title: string; description: string };
}

export const ERROR_PAGE: ErrorPageTranslation = {
  cs: {
    title: "Je nám líto, služba je dočasně nedostupná",
    description: "Zkuste to znovu později, opravíme to co nejdříve",
  },
  de: {
    title: "Ups – dieser Service ist vorübergehend nicht verfügbar.",
    description:
      "Bitte versuche es später noch einmal. Wir arbeiten auf Hochtouren, um den Fehler zu beheben.",
  },
  es: {
    title: "Vaya, el servicio no está disponible temporalmente",
    description:
      "Por favor, inténtalo de nuevo más tarde. Lo arreglaremos lo antes posible.",
  },
  fr: {
    title: "Oups ! Ce service est temporairement indisponible.",
    description:
      "Reviens sur cette page un peu plus tard. Nous règlerons le problème dès que possible.",
  },
  it: {
    title: "Ops! Il servizio è temporaneamente non disponibile",
    description: "Riprova più tardi, sarà ripristinato al più presto",
  },
  nl: {
    title: "Oeps, service tijdelijk niet beschikbaar",
    description: "Probeer het later opnieuw, we lossen het zo snel mogelijk op",
  },
  pl: {
    title: "Ups! Usługa chwilowo niedostępna",
    description:
      "Spróbuj ponownie później. Postaramy się jak najszybciej naprawić usterkę.",
  },
  pt: {
    title: "Ups. Serviço temporariamente indisponível",
    description:
      "Verifica novamente mais tarde. Vamos corrigi-lo o mais rapidamente possível",
  },
  sl: {
    title: "Ojoj, storitev trenutno ni na voljo.",
    description: "Poskusite znova pozneje, hitro bomo uredili zadevo.",
  },
  "en-us": {
    title: "Oops! Service temporarily unavailable",
    description: "Please check back again later: we'll get it fixed ASAP",
  },
  ro: {
    title: "Ne pare rău, serviciul este temporar indisponibil",
    description:
      "Te rugăm să verifici din nou mai târziu, vom remedia problema cât de curând",
  },
  sk: {
    title: "Ľutujeme, služba je dočasne nedostupná",
    description: "Skúste to znova neskôr. Vyriešime to čo najskôr.",
  },
  default: {
    title: "Oops, service temporarily unavailable",
    description: "Please check back again later, we'll have it fixed asap",
  },
};
