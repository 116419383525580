import { useCallback } from "react";

interface ReturnValue {
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value: string) => boolean;
  removeItem: (key: string) => boolean;
}

const isStorageExist = () => typeof sessionStorage !== "undefined";

export const useSessionStorage = (): ReturnValue => {
  const getItem = useCallback((key: string) => {
    if (!isStorageExist()) {
      return undefined;
    }

    return sessionStorage.getItem(key);
  }, []);

  const setItem = useCallback((key: string, value: string): boolean => {
    if (!isStorageExist()) {
      return false;
    }

    sessionStorage.setItem(key, value);
    return true;
  }, []);

  const removeItem = useCallback((key: string): boolean => {
    if (!isStorageExist()) {
      return false;
    }

    sessionStorage.removeItem(key);
    return true;
  }, []);

  return {
    getItem,
    setItem,
    removeItem,
  };
};
